import React, { Fragment, PureComponent } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import flatten from "lodash/flatten";
import first from "lodash/first";
import last from "lodash/last";

import Dialog from "../Dialog";
import DialogButton from "../DialogButton";
import AssessmentAnswers from "../../containers/Assessments/AssessmentAnswers";

import placeholderPhoto from "../../assets/images/placeholder-avatar.svg";

import config from "../../config";

import messages from "./messages";
import studentMessages from "../CardResultStudent/messages";
import "./index.scss";

function formatNumber(value, locale) {
  const number = parseFloat(value);
  if (locale === "en") return number;
  return number.toString().replace(".", ",");
}

function getWidth(grade) {
  return parseFloat(grade) ? `${grade}%` : "1px";
}

function sortCatalogings(c1, c2) {
  return parseFloat(c1.grade) - parseFloat(c2.grade);
}

class ResultStudentDialog extends PureComponent {
  state = { viewMore: false, viewMoreThematicAxis: false };

  toggleViewMore = () => {
    this.setState(({ viewMore }) => ({
      viewMore: !viewMore,
    }));
  };

  toggleViewMoreThematicAxis = () => {
    this.setState(({ viewMoreThematicAxis }) => ({
      viewMoreThematicAxis: !viewMoreThematicAxis,
    }));
  };

  componentDidUpdate() {
    const video = document.getElementsByTagName("video");
    const audio = document.getElementsByTagName("audio");

    if (video.length > 0) {
      Array.from(video).forEach(el => {
        el.setAttribute("controlsList", "nodownload");
      });
    }
    if (audio.length > 0) {
      Array.from(audio).forEach(el => {
        el.setAttribute("controlsList", "nodownload");
      });
    }
  }

  render() {
    const {
      onClose,
      visible,
      details,
      contentLabel,
      intl,
      answers,
      hasLikertQuestion,
      showStudentReportDetails,
      studentAttempt,
    } = this.props;
    const { viewMore, viewMoreThematicAxis } = this.state;

    if (!details) return null;

    console.log("showStudentReportDetails", showStudentReportDetails);

    const {
      firstname,
      lastname,
      photo,
      schoolName,
      courseName,
      grade,
      courseAverageGrade,
      disciplines,
      attempts,
    } = details;

    // console.log("disciplines", disciplines);
    let catalogings =
      answers && answers.disciplines.length > 0
        ? flatten(answers.disciplines.map(d => d.cataloging)).sort(sortCatalogings)
        : [];
    // let catalogings = disciplines
    //   ? flatten(disciplines.map(d => d.cataloging)).sort(sortCatalogings)
    //   : [];

    const catalogingsView =
      catalogings.length > 1
        ? viewMore
          ? catalogings
          : [first(catalogings), last(catalogings)]
        : [first(catalogings)];
    const catalogingsData = catalogingsView.filter(el => !!el);
    const disciplinesData = disciplines ? disciplines.filter(el => !!el) : [];

    let thematicAxisView = [];
    if (answers && answers.thematicAxis) {
      thematicAxisView =
        answers.thematicAxis.length > 1
          ? viewMoreThematicAxis
            ? answers.thematicAxis
            : [first(answers.thematicAxis), last(answers.thematicAxis)]
          : [first(answers.thematicAxis)];
    }

    const thematicAxisData = thematicAxisView.filter(el => !!el);

    const photoSrc = photo ? `${config.UPLOAD_PATH}/${photo}` : placeholderPhoto;
    const schoolNameAux = schoolName ? schoolName : disciplinesData[0].name;
    const attempt = attempts.filter(el => el.firstname === firstname && el.lastname === lastname);

    console.log("studentAttempt", studentAttempt);

    return (
      <Dialog
        title={`${firstname} ${lastname}`}
        visible={visible}
        subtitle={`${courseName} - ${schoolNameAux}`}
        iconRender={() => (
          <img alt="" className="avatar-image" src={photoSrc} style={{ maxHeight: 50 }} />
        )}
        onClose={onClose}
      >
        <div className="result-student-dialog">
          <div className="bar-chart horizontal-bars-chart">
            <div className="bar-chart-wrapper">
              <div className="bc-row">
                <div className="bc-bar bc--orange" style={{ width: getWidth(grade) }}>
                  <div className="bc-bar-value">{formatNumber(grade, intl.locale)}%</div>
                </div>
              </div>
            </div>
            <div className="bc-number">0</div>
          </div>
          <div className="summary">
            <div className="row">
              {attempt && attempt[0].scoreCalculated
                ? attempt[0].scoreCalculated.map((el, i) => (
                    <div key={i} className="col-sm-6 mb-3">
                      <div className="summary-unitary">
                        <div>{el.label}</div>
                        <div className="percent">{parseInt(el.result)}</div>
                      </div>
                    </div>
                  ))
                : disciplinesData.map((discipline, i) => (
                    <div key={i} className="col-sm-6 mb-3">
                      <div className="summary-unitary">
                        <div>{discipline.name}</div>
                        <div className="percent">{discipline.grade}%</div>
                      </div>
                    </div>
                  ))}

              {attempt &&
                attempt[0].gradeCalculated &&
                attempt[0].gradeCalculated.map((el, i) => (
                  <div key={i} className="col-sm-6 mb-3">
                    <div className="summary-unitary">
                      <div>{el.label}</div>
                      <div className="percent">{parseFloat(el.result).toFixed(1)}</div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="row">
              {/* <FormattedMessage {...messages.studentAverage}>
                {txt => (
                  <div className="col-sm-6 mb-3">
                    <div className="summary-unitary">
                      <div>{txt}</div>
                      <div className="percent">{formatNumber(grade, intl.locale)}%</div>
                    </div>
                  </div>
                )}
              </FormattedMessage> */}

              <div className="col-sm-6 mb-3">
                <div className="summary-unitary">
                  <div>
                    {!hasLikertQuestion ? (
                      <FormattedMessage {...messages.studentAverage} />
                    ) : (
                      <FormattedMessage {...messages.studentAverageLikert} />
                    )}
                  </div>
                  <div className="percent">{formatNumber(grade, intl.locale)}%</div>
                </div>
              </div>

              {/* <FormattedMessage {...messages.courseAverage}>
                {txt => (
                  <div className="col-sm-6 mb-3">
                    <div className="summary-unitary">
                      <div>{txt}</div>
                      <div className="percent">
                        {formatNumber(courseAverageGrade, intl.locale)}%
                      </div>
                    </div>
                  </div>
                )}
              </FormattedMessage> */}

              <div className="col-sm-6 mb-3">
                <div className="summary-unitary">
                  <div>
                    {!hasLikertQuestion ? (
                      <FormattedMessage {...messages.courseAverage} />
                    ) : (
                      <FormattedMessage {...messages.courseAverageLikert} />
                    )}
                  </div>
                  <div className="percent">{formatNumber(courseAverageGrade, intl.locale)}%</div>
                </div>
              </div>

              <hr />
            </div>
          </div>
          {showStudentReportDetails && studentAttempt && (
            <div
              className="questionsAnswersCount"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                flexWrap: "wrap",
                width: "270px",
                paddingBottom: "5px",
                margin: "30px auto",
              }}
            >
              <div>
                {hasLikertQuestion ? (
                  <FormattedMessage {...studentMessages.answered} />
                ) : (
                  <FormattedMessage {...studentMessages.good} />
                )}
                : <b>{studentAttempt.answers.hits}</b>
              </div>
              {!hasLikertQuestion && (
                <div>
                  <FormattedMessage {...studentMessages.bad} />:{" "}
                  <b>{studentAttempt.answers.errors}</b>
                </div>
              )}
              <div>
                <FormattedMessage {...studentMessages.ommited} />:{" "}
                <b>{studentAttempt.answers.ommited}</b>
              </div>
              {studentAttempt.answers.notQualified > 0 && (
                <div>
                  <FormattedMessage {...studentMessages.notQualified} />:{" "}
                  <b>{studentAttempt.answers.notQualified}</b>
                </div>
              )}
            </div>
          )}
          <div className="conteudo">
            <div className="conteudo-title">
              <i className="ti-layers-alt" />
              {contentLabel}
            </div>
            <div className="bar-chart bar-chart-compact horizontal-bars-chart no-border">
              <div className="bar-chart-wrapper">
                {catalogingsData.map((cataloging, i) => (
                  <Fragment key={i}>
                    <p className="mt-3">{cataloging.name}</p>
                    <div className="bc-row">
                      <div
                        className="bc-bar bc--orange"
                        style={{ width: getWidth(cataloging.grade) }}
                      >
                        <div className="bc-bar-value">{cataloging.grade}%</div>
                      </div>
                    </div>
                  </Fragment>
                ))}
              </div>
            </div>
            {catalogings.length > 2 && (
              <div style={{ margin: "10px 0" }}>
                <Fragment>
                  <FormattedMessage {...(viewMore ? messages.viewLess : messages.viewMore)}>
                    {txt => <DialogButton onClick={this.toggleViewMore}>{txt}</DialogButton>}
                  </FormattedMessage>
                </Fragment>
              </div>
            )}
            {answers && answers.thematicAxisLabel && (
              <div style={{ borderTop: "1px solid #eee", marginTop: "15px", paddingTop: "15px" }}>
                <div className="conteudo-title">
                  <i className="ti-layers-alt" />
                  {answers.thematicAxisLabel}
                </div>
                <div className="bar-chart bar-chart-compact horizontal-bars-chart no-border">
                  <div className="bar-chart-wrapper">
                    {thematicAxisData.map((thematicAxis, i) => (
                      <Fragment key={i}>
                        <p className="mt-3">{thematicAxis.name}</p>
                        <div className="bc-row">
                          <div
                            className="bc-bar bc--orange"
                            style={{ width: getWidth(thematicAxis.grade) }}
                          >
                            <div className="bc-bar-value">{thematicAxis.grade}%</div>
                          </div>
                        </div>
                      </Fragment>
                    ))}
                  </div>
                </div>
                {answers.thematicAxis.length > 2 && (
                  <div style={{ margin: "10px 0" }}>
                    <Fragment>
                      <FormattedMessage
                        {...(viewMoreThematicAxis ? messages.viewLess : messages.viewMore)}
                      >
                        {txt => (
                          <DialogButton onClick={this.toggleViewMoreThematicAxis}>
                            {txt}
                          </DialogButton>
                        )}
                      </FormattedMessage>
                    </Fragment>
                  </div>
                )}
              </div>
            )}
            <div style={{ borderTop: "1px solid #eee", marginTop: "15px", paddingTop: "15px" }}>
              <AssessmentAnswers
                questions={answers ? answers.questions : undefined}
                hasUserEditGradePermission={this.props.hasUserEditGradePermission}
                onEditGrade={this.props.onEditGrade}
                onRemoveGrade={this.props.onRemoveGrade}
              />
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default injectIntl(ResultStudentDialog);
